import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps, PropsWithChildren } from "react";
import { isObject } from "lodash-es";
import { cn } from "@/common/utils/css.utils";

const LabelStyle = cva(
  "flex items-center gap-1.5 rounded-full px-2 py-1 font-body text-sm",
  {
    variants: {
      color: {
        burst: "bg-burst-100 text-burst-900",
        curious: "bg-curious-50 text-curious-800",
        brand: "bg-flavour-100 text-brand-500",
        attention: "bg-attention-50 text-attention-800",
      },
    },
    defaultVariants: {
      color: "curious",
    },
  }
);

export type InfoLabelProps = {
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
} & Omit<ComponentProps<"label">, "color"> &
  VariantProps<typeof LabelStyle>;

export const InfoLabel = ({
  color,
  leftIcon,
  rightIcon,
  className,
  children,
  ...props
}: PropsWithChildren<InfoLabelProps>) => {
  const renderChildren = () => {
    if (!isObject(children)) {
      return <span>{children}</span>;
    }

    return children;
  };

  return (
    <label className={cn(LabelStyle({ color }), className)} {...props}>
      {leftIcon}
      {renderChildren()}
      {rightIcon}
    </label>
  );
};
