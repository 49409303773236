import { useIsBrengerPromoFeatureActive } from "@/features/brenger-promo/hooks/useIsBrengerPromoFeatureActive";
import { InfoLabel } from "@/ui/cva/InfoLabel";
import { useTranslation } from "next-i18next";

export const ProductCardBrengerPromoLabel = () => {
  const { t } = useTranslation();
  const { isActive } = useIsBrengerPromoFeatureActive();

  if (!isActive) {
    return null;
  }

  return (
    <InfoLabel className="absolute -left-2 top-4 z-10">
      {t("brenger-promotion:brenger-promotion-product-card-label")}
    </InfoLabel>
  );
};
