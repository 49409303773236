import { ShippingProviderEnum } from "@/Apollo/schema";
import { ProductUnknown } from "@/common/contracts/product.contracts";

export const useIsBrengerShippingProviderPresentOnProduct = (
  product: ProductUnknown
) => {
  const isPresent =
    product.shippingOptions?.some(
      option => option?.shippingProvider === ShippingProviderEnum.Brenger
    ) ?? false;

  return { isPresent };
};
